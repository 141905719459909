body.index {
    section + section {
        margin-top: 45px;
    }
}

.frontpage-text-lifts {
    .make-lg-column(9);
    @media (min-width: @screen-sm-min) {
        display: flex;
    }

    .lift-column {
        position: relative;
        padding-bottom: 20px;

        @media (max-width: @screen-xs-max) {
            border-top: 1px solid @border-color;
        }

        @media (min-width: @screen-sm-min) {
            .make-sm-column(4);
            flex: 0 auto;
            border-right: 1px solid @border-color;
            padding-bottom: 50px;
            &:last-child {
                border-right: 0;
            }
        }

        .column-title {
            margin: 15px 0;

            @media (min-width: @screen-sm-min) {
                .row;
                margin-top: 0;
            }

            > h3 {
                margin-top: 10px;
                padding-bottom: 10px;
                padding-top: 5px;

                @media (min-width: @screen-sm-min) {
                    border-bottom: 1px solid @border-color;
                    padding: 0 30px 10px 30px;
                }
            }
        }

        .single-post {
            padding-bottom: 10px;
            @media (min-width: @screen-sm-min) {
                padding: 0px 15px 10px 15px;
            }
            .title {
                font-weight: bold;
                color: @gray-dark;
                &:hover {
                    color: @brand-primary;
                    text-decoration: underline;
                }
            }
            .text {
                color: @gray-light;
            }
        }

        .read-all-wrap {
            margin: 15px 0px;
            @media (min-width: @screen-sm-min) {
                margin: 0;
                text-align: center;
                position: absolute;
                bottom: 0;
                left: 0;
                right: 0;
            }
            .read-all-btn {
                .btn;
                padding: 8px 20px;
                color: @brand-primary;
                border: 1px solid #ddd;
                font-size: 1.2rem;
                font-weight: 600;
                background: linear-gradient(to bottom, #fff, #F3F3F3);
            }
        }
    }
}
