.carousel {
    height: 100%;
    .carousel-control {
        width: 50px;
        @media (min-width: @screen-sm-min) {
            width: 75px;
        }
    }
    .carousel-control.left,
    .carousel-control.right {
        background-image: none;
        display: flex;
        z-index: 3;
        > span {
            flex: auto;
            align-self: center;
        }
    }
    .bg-icon {
        background-repeat: no-repeat;
        background-size: 50%;
        background-position: 50%;
        display: block;
        padding-top: 100%;
        width: 100%;
        @media (min-width: @screen-sm-min) {
            background-size: auto;
        }

        &.white-arrow-left {
            background-image: url("../img/white-arrow-left.png");
        }
        &.white-arrow-right {
            background-image: url("../img/white-arrow-right.png");
        }
    }
    &.fade {
        opacity: 1;

        .item {
            display: block !important;
            position: absolute;
            left: 0 !important;
            top: 0;
            width: 100%;
            height: 100%;
            z-index: 1;
            opacity: 0;
            transition: opacity 0.6s ease-in-out;

            &:first-child {
                position: relative;
                top: auto;
            }

            &.active {
                height: 100%;
                z-index: 2;
                opacity: 1;
                transition: opacity 1s ease-in-out;
            }
        }
    }
    .carousel-indicators {
        width: auto;
        left: auto;
        bottom: auto;
        top: 10px;
        right: 13px;
        margin: 0;
        li {
            width: 18px;
            height: 18px;
            border: 2px solid @gray;
            background: #fff;
            margin: 0 1px;
            &.active {
                background: @brand-primary;
                height: 18px;
                width: 18px;
                margin: 0 1px;
            }
        }
    }
}

.owl-carousel.thumbnails {
    margin-top: 15px;
    position: relative;
    &.nav-padding {
        padding: 0px 30px;
        .owl-nav {
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            z-index: -1;
        }
        .owl-prev, .owl-next {
            left: 0;
            top: 0;
            width: 30px;
            display: flex;
            align-items: center;
            position: absolute;
            height: 100%;
            justify-content: center;
            &.disabled {
                color: @gray-lighter;
            }
        }
        .owl-next {
            left: auto;
            right: 0;
        }
    }
}

.cmsplugin-product-highlight {
    .clearfix();

    .title {
        &:after {
            content: " ::";
        }
    }

    .owl-stage {
        align-items: stretch;
        display: flex;
    }

    .owl-item {
        background-color: #f7f7f7;
        box-shadow: 0 0 3px 2px rgba(0, 0, 0, 0.08);

        .image {
            background-color: #fff;
            padding: 10px;
        }

        .content {
            padding: 10px;

            .name {
                margin-bottom: 10px;
                text-transform: uppercase;
            }

            .description {
                color: @text-muted;
                margin: 10px 0;
                max-height: 150px;
                overflow: hidden;
                position: relative;

                &:after {
                    background: linear-gradient(transparent, #f7f7f7);
                    bottom: 0;
                    content: "";
                    height: 25px;
                    left: 0;
                    position: absolute;
                    right: 0;
                }
            }

            .price {
                font-size: 1.6rem;
                font-weight: bold;
                margin: 10px 0 40px 0;
            }

            .button {
                bottom: 10px;
                left: 5%;
                position: absolute;
                right: 95%;
                width: 90%;

                .btn {
                    width: 100%;
                }
            }
        }
    }

    .owl-nav {
        left: 0;
        position: absolute;
        top: 50%;
        width: 100%;

        .owl-prev {
            float: left;
            margin-left: -30px;
        }

        .owl-next {
            float: right;
            margin-right: -30px;
        }

        i {
            color: #bebebe;
            font-size: 4rem;
        }

        .disabled > i {
            color: #eeeeee;
        }
    }
}
