//
// COMMON STYLES FOR ALL ARTICLE TYPES
//
.post-list {
    .single-post {
        margin-bottom: 45px;
    }
    .post-image-wrap {
        .make-md-column(4);
    }

    .post-summary-wrap {
        .make-md-column(8);
    }

    h2, h4 {
        margin: 15px 0px;
        word-wrap: break-word;

        a {
            color: @gray-dark;
            &:hover, &:focus {
                color: @brand-primary;
            }
        }
    }

    .read-post-btn-news-anchor {
        text-decoration: none;
    }
    .read-post-btn-news {
        text-align: center;
        margin: 15px 0;
        padding: 5px;
        color: white;
        background-color: @brand-primary;
        border-radius: 50px;
    }
    .read-post-btn {
        color: @brand-primary;
        border: 1px solid @border-darkened;
        padding-top: 15px;
        padding-bottom: 15px;
        font-weight: 600;
        position: relative;
        width: 100%;
        display: block;
        margin-top: 25px;
        text-align: center;
        font-size: 1.3rem;
        &:hover, &:focus {
            background: @gray-lighter;
            border-color: @border-darkened;
            i {
                color: @gray;
            }
        }
        i {
            color: @border-darkened;
            position: absolute;
            right: 15px;
            top: 12px;
            font-size: 2rem;
        }
    }
}

.post-metadata {
    margin-bottom: 15px;
    color: @gray-light;
    font-weight: 600;
    font-style: italic;
    font-size: 1.2rem;
}

.article-content {
    h1 {
        font-size: 28px;
        word-wrap: break-word;
    }

    h2 {
        font-size: 24px;
    }

    h3 {
        font-size: 20px;
    }

    .article-text {
        padding-bottom: 30px;
        word-wrap: break-word;
        img {
            height: auto !important;
        }
    }

    iframe {
        max-width: 100%;
    }
}

.article-sidebar {
    .sidebar-section {
        margin-bottom: 50px;
    }
    .article-sidebar-image {
        .image-description {
            margin: 15px;
            font-style: italic;
        }
    }
    ul:not(.tag-cloud) {
        padding: 0;
        list-style: none;
        li {
            position: relative;
            margin-bottom: 3px;
        }
        li:before {
            content: "\25AA";
            display: inline-block;
            vertical-align: middle;
            position: absolute;
            left: -10px;
        }
    }
    .author-name,
    .author-img,
    .author-description {
        margin-bottom: 15px;
    }
    .author-description {
        font-size: 1.3rem;
    }
    .author-social-media {
        .social-block {
            display: table;
            width: 100%;
            padding: 10px;
            border-top: 1px solid @border-color;
            color: @text-color;
            text-decoration: none;

            &:last-child {
                border-bottom: 1px solid @border-color;
            }

            i.fa {
                font-size: 3rem;
                display: table-cell;
                vertical-align: middle;
                width: 3.6rem;

                &.fa-facebook-square {
                    color: #3b5998;
                }

                &.fa-twitter {
                    color: #50abf1;
                }

                &.fa-linkedin-square {
                    color: #0077b5;
                }
            }

            span {
                display: table-cell;
                vertical-align: middle;
                line-height: 1;
                font-weight: 600;
            }
        }
    }
}

.more-articles-btn {
    color: #fff;
    padding-top: 15px;
    padding-bottom: 15px;
    font-weight: 600;
    position: relative;
    width: 100%;
    display: block;
    margin-top: 15px;
    text-align: center;
    font-size: 1.3rem;
    background: @brand-primary;
    &:hover, &:focus {
        background: lighten(@brand-primary, 5%);
        color: #fff;
    }
    i {
        color: #fff;
        position: absolute;
        right: 15px;
        top: 10px;
        font-size: 2.8rem;
    }
}

//
// BLOG SPECIFIC STYLING
//
body.blog {
    .article-content {
        .make-md-column(8);

        .article-images {
            .default-image {
                height: 384px;
                width: auto;
            }
        }
    }
    .article-sidebar {
        .make-md-column(3);
        .make-md-column-push(1);
    }
    .post-list .single-post:nth-child(odd) {
        .post-summary-wrap {
            .make-md-column-pull(4);
        }
        .post-image-wrap {
            .make-md-column-push(8);
        }
    }

    ul.tag-cloud {
        list-style: none;
        padding: 0;
        text-align: center;
        width: 100%;

        li {
            display: inline-block;
            margin-right: 5px;

            &.size1 {
                font-size: 2.6rem;
                font-weight: 800;
            }

            &.size2 {
                font-size: 2.1rem;
                font-weight: 700;
            }

            &.size3 {
                font-size: 1.6rem;
                font-weight: 600;
            }

            &.size4 {
                font-size: 1.2rem;
                font-weight: 400;
            }
        }
    }
}

//
// ARTICLES SPECIFIC STYLING
//
body.articles {
    .article-content {
        .make-md-column(7);
    }
    .article-sidebar {
        .make-md-column(5);

        .default-image {
            height: auto;
            margin-left: auto;
            margin-right: auto;
            width: 80%;
        }
    }
    h1.article-title {
        @media (min-width: @screen-md-min) {
            margin-top: 0;
        }
    }
    .sidebar-product-lift {
        h2 {
            margin: 30px 0px;
            color: @brand-primary;
        }
        .sidebar-masonry-grid {
            .clearfix;
            margin-left: -10px;
            margin-right: -10px;
            .column {
                float: left;
                padding: 10px;
            }
            .size-1of1 {
                width: 100%;
            }
            .size-1of2 {
                width: 50%;
            }
            .size-1of3 {
                width: 33.333%;
            }
            .card {
                margin-bottom: 20px;
                display: block;
            }
            .card-description {
                max-height: 125px;
                overflow: hidden;
                position: relative;

                &:after {
                    background: linear-gradient(transparent, #f7f7f7);
                    bottom: 0;
                    content: "";
                    height: 25px;
                    left: 0;
                    position: absolute;
                    right: 0;
                }
            }
        }
        .sidebar-masonry-grid[data-columns]:before {
            @media (max-width: 450px) {
                content: '1 .column.size-1of1';
            }
            @media (min-width: 451px) and (max-width: @screen-xs-max) {
                content: '2 .column.size-1of2';
            }
            @media (min-width: @screen-sm-min) and (max-width: @screen-sm-max) {
                content: '3 .column.size-1of3';
            }
            @media (min-width: @screen-md-min) {
                content: '2 .column.size-1of2';
            }
        }
    }
}

//
// NEWS SPECIFIC STYLING
//
body.news {
    .post-list {
        .post-summary-wrap {
            .make-md-column-pull(4);
        }
        .post-image-wrap {
            .make-md-column-push(8);
        }
    }

    .news-list-secondary {
        padding-top: 15px;
    }

    .small-news-lift {
        .make-md-column(6);

        &:nth-child(2n+1) {
            clear: both;
        }

        hr {
            margin: 30px 0;
        }
    }

    .article-content {
        .make-md-column(8);

        .article-images {
            .default-image {
                height: 384px;
                width: auto;
            }
        }
    }
    .article-sidebar {
        .make-md-column(3);
        .make-md-column-push(1);
    }

    .news-events {

        a {
            text-decoration: none;
        }

        .events-header {
            padding: 15px;
            color: white;
            font-weight: bold;

            .question {
                float: right;
            }

            i {
                font-size: 20px;
            }
        }

        .selectpicker-wrapper {
            padding: 15px;
            background-color: @light-gray-bg;

            .title {
                font-size: @font-size-large;
            }

            .bootstrap-select {
                width: 100%;
                border-color: white;
            }

            .btn-default {
                border-color: @light-gray-bg;
                border-radius: 5px;
            }

            .btn-default {
                border-color: @light-gray-bg;
            }

            .btn-default:hover {
                border-color: @light-gray-bg;
            }

            .caret {
                height: 0; // make it invisible, but the :before still applies

                &:before {
                    color: black;
                }
            }
        }

        .events-header.upcoming {
            background-color: @brand-primary;
        }

        .events-header.past {
            background-color: @gray;
            margin-top: 50px;
        }

        .event-link {
            padding-bottom: 15px;
            border-bottom: 1px solid @gray;
        }

        .event-link-text {
            color: black;
            .raquo {
                color: @brand-primary;
            }
        }

        .event-date {
            font-weight: bold;

            .day {
                text-transform: capitalize;
            }
        }

        .department-store-label-wrapper {
            text-transform: uppercase;
            margin: 15px 0 5px 0;
        }

        .events-past {
            .department-store-label {
                background-color: @gray;
            }

            .raquo {
                color: @gray;
            }
        }

        .subevents {
            color: black;

            .arrow {
                margin-top: 6px;
                transform: rotate(45deg);
                border-top: 1px solid @brand-primary;
                border-right: 1px solid @brand-primary;
                height: 8px;
                width: 8px;
                float: left;
                color: @brand-primary;
                margin-right: 15px;

                .invisible {
                    color: white;
                }
            }

            .subevent-time {
                font-weight: bold;
            }
        }
    }
}

.article-preview {
    ins, del {
        color: white;
        padding: 0.2em;
        text-decoration: none;
    }

    ins {
        background-color: green;

        iframe {
            border: 10px solid green;
        }
    }

    del {
        background-color: red;

        iframe {
            border: 10px solid red;
        }
    }
}
