footer {
    background: @brand-primary;
    color: #fff;
    padding: 75px 5px;
    a {
        color: #fff;
        &:hover, &:focus {
            color: #fff;
            text-decoration: underline;
        }
    }
    .logo {
        margin-bottom: 50px;
        padding: 0px 30px;
    }

    .socialmedia-icon-plugin {
        margin-top: 35px;
        padding: 0 30px;

        li {
            .size(36px; 36px);
            background: #fff;
            display: inline-block;
            line-height: 36px;
            margin: 0 5px 5px 0;
            text-align: center;
            vertical-align: middle;

            i {
                color: @brand-primary;
                font-size: 24px;
                vertical-align: middle;
            }
        }
    }

    .contact-list-plugin {
        dt {
            font-size: 18px;
            margin-bottom: 10px;
        }

        dd {
            margin-bottom: 20px;
        }

        ul {
            list-style: none;
            padding-left: 0;
        }
    }

    .additional-text {
        font-weight: bold;
        padding: 0px 30px;
    }
    .links {
        padding: 0px 30px;
        ul {
            list-style: none;
            padding: 0;
            margin: 0;
            border-top: 1px solid #fff;
            li {
                display: block;
                border-bottom: 1px solid #fff;
                a {
                    display: block;
                    padding: 6px 0px;
                    &:after {
                        content: "\00BB"; //raquo
                        padding-left: 5px;
                        text-decoration: none !important;
                    }
                }
            }
        }
        + .links ul {
            @media (max-width: @screen-sm-max) {
                border-top: 0;
            }
        }
    }
    .content-right {
        padding: 0px 30px;
    }
}
