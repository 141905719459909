body.magazines {
    .magazine-column {
        .make-sm-column(6);
        .make-md-column(4);

        @media (min-width: @screen-sm-min) and (max-width: @screen-sm-max) {
            &:nth-child(2n+1) {
                clear: both;
            }
        }

        @media (max-width: @screen-sm-max) {
            padding-bottom: 30px;
        }

        @media (max-width: @screen-xs-max) {
            padding-top: 30px;
        }
    }

    .magazine-column + .magazine-column {
        @media (max-width: @screen-xs-max) {
            border-top: 1px solid @border-color;
        }
    }

    .magazine-title {
        margin-top: 0;
        margin-bottom: 25px;
    }

    .magazine-hero {
        display: block;
        img {
            margin-bottom: 15px;
        }
        p {
            margin-bottom: 20px;
            height: 2.8em;

            @media (min-width: @screen-md-min) {
                font-size: 1.6rem;
            }
        }
    }

    .show-all-btn {
        .btn;
        border-radius: 0px;
        color: #fff;
        padding-top: 12px;
        padding-bottom: 12px;
        font-weight: 600;
        position: relative;
        width: 100%;
        display: block;
        margin-bottom: 15px;
        text-align: center;
        font-size: 1.3rem;
        border: 0;
        background: @brand-primary;
        &:hover, &:focus {
            background: lighten(@brand-primary, 5%);
            color: #fff;
        }

        i {
            position: absolute;
            right: 15px;
            top: 11px;
            font-size: 2rem;
        }
    }
}
