body.stores {
    blockquote {
        h1 {
            margin-top: 0;
        }
        p {
            font-size: 1.6rem;
            color: @gray;
        }
    }
}
