.cmsplugin-department-stores {
    margin-top: 45px;

    .single-store:nth-child(even) {
        .store-map {
            .make-md-column-pull(6);
        }

        .store-details {
            .make-md-column-push(6);
        }
    }

    .single-store {
        border-bottom: 1px solid @border-color;
        margin-bottom: 30px;
        padding-bottom: 30px;

        &:last-child {
            border: 0;
            margin-bottom: 0;
        }

        .store-details,
        .store-map {
            .make-md-column(6);
        }

        .main-image {
            margin-bottom: 30px;
        }

        .store-text {
            margin-bottom: 30px;

            h3 {
                margin-top: 0;
            }

            p {
                color: @gray;
            }
        }

        h4 {
            font-size: 14px;
            font-weight: 600;
            margin-bottom: 5px;
        }

        .title {
            font-weight: 600;
            margin-bottom: 5px;
        }

        .opening-hours {
            margin-bottom: 30px;

            h4:before {
                content: "\f017";
                font-family: "FontAwesome";
                margin-right: 7px;
            }

            .calendar-tabs {
                margin-top: 20px;

                ul.tab-row {
                    padding: 0;
                    margin: 0;
                    list-style: none;

                    > li {
                        display: inline-block;

                        &.text {
                            color: @gray;
                            span {
                                padding: 6px 20px;
                            }
                        }

                        &.active {
                            a {
                                color: @brand-primary;
                                background: @light-gray-bg;

                                &:focus {
                                    background: @light-gray-bg;
                                    color: @brand-primary;
                                }
                            }
                        }

                        a,
                        button {
                            background: @brand-primary;
                            color: #fff;
                            font-weight: 600;
                            &:hover, &:focus {
                                text-decoration: none;
                                background: @gray;
                                color: #fff;
                            }
                        }

                        a {
                            padding: 6px 12px;
                            outline: 0;
                        }

                        button {
                            padding: 6px 16px;
                            border: 0;
                            border: solid #fff;
                            border-width: 0px 1px;
                        }
                    }
                }

                .tab-content {
                    background: @light-gray-bg;
                    padding: 15px 25px;

                    @media (min-width: @screen-sm-min) {
                        padding: 15px 50px;
                    }

                    .weekdays {
                        margin-bottom: 20px;
                    }

                    ul {
                        column-count: 2;
                        list-style: none;
                        margin: 0;
                        padding: 0;

                        li {
                            margin-bottom: 14px;

                            .title {
                                display: inline-block;
                                margin: 0;
                                width: 40px;
                            }

                            .closed {
                                color: @brand-danger;
                            }
                        }
                    }

                    a.extra-shop-link {
                        margin-top: 20px;
                        display: inline-block;

                        &:after {
                            content: "\00BB";
                            margin-left: 3px;
                        }
                    }
                }
            }
        }

        .store-contact-info {
            color: @gray-dark;
            @media (max-width: @screen-xs-max) {
                margin-bottom: 30px;
            }
            .phone {
                .make-sm-column(6);
                font-size: 23px;
                @media (min-width: @screen-lg-min) {
                    font-size: 28px;
                }

                text-decoration: none;
                i {
                    margin-right: 5px;
                }
                margin-top: 5px;
            }
            address {
                margin-bottom: 0;
            }
            .contact-info {
                padding: 5px;
                margin: 15px 0;
                background-color: @light-gray-bg;
            }
        }

        a {
            text-decoration: none;
        }

        .modal-button {
            text-align: center;
            margin: 15px 0;
            padding: 5px;
            color: white;
            background-color: @brand-primary;
            border-radius: 50px;
        }

        .store-images {
            .make-sm-column(6);

            @media (max-width: @screen-xs-max) {
                margin-bottom: 30px;
            }

            .gallery-wrap {
                display: table;
                width: 100%;

                .icon {
                    height: 80px;
                    width: 80px;
                    border-radius: 50%;
                    display: block;
                    background: @brand-primary;
                    color: #fff;
                    text-decoration: none;
                    margin-right: 15px;

                    i {
                        position: relative;
                        top: 50%;
                        left: 50%;
                        font-size: 46px;
                        transform: translate3d(-50%, -50%, 0);
                    }
                }

                span {
                    text-transform: uppercase;
                    display: table-cell;
                    vertical-align: middle;
                }
            }
        }

        .events {
            h4:before {
                content: "\f0a1";
                font-family: "FontAwesome";
                margin-right: 7px;
            }

            ul {
                background-color: @light-gray-bg;
                color: @brand-primary;
                display: block;
                width: 100%;
                li {
                    list-style: disc;
                }
            }

            .event-list {
                padding: 15px 0 15px 70px;
            }

            .modal-events {
                color: @gray-base;
            }
        }
    }

    .modal-departments {
        td {
            padding: 0 15px 5px 0;

            &:first-child {
                text-align: right;
            }
        }
    }
}
