body {
    &.custom-bg {
        background-position: 50%;
        background-attachment: fixed;
        background-size: cover;
        background-repeat: no-repeat;
    }
}

img {
    max-width: 100%;
    height: auto;
}

.btn {
    &-rounded {
        border-radius: 30px;
    }
}

.container {
    &.main {
        background: #fff;
    }
    &.gradient-bg {
        border-top: 1px solid #bababa;
        background: linear-gradient(to bottom, #d6d6d6, #fff 50%);
    }
}

.top-banner .carousel {
    border-bottom: 1px solid #fff;
}

.content-header {
    background: #f0f0f0;
    padding: 15px 20px;
    @media (min-width: @screen-lg-min) {
        padding: 15px 30px;
    }
    &-title {
        color: @brand-primary;
        h1, h2 {
            font-size: 28px;
            font-style: italic;
            margin: 0;
            font-weight: 800;
            display: inline-block;
            vertical-align: middle;
            color: @brand-primary;
        }
        i {
            display: inline-block;
            font-size: 52px;
            margin-left: 15px;
            vertical-align: middle;
        }
    }
    &-additions {
        line-height: 52px;
    }
}

.body-wrapper {
    .clearfix;
    padding: 20px 0px 50px 0px;
    @media (min-width: @screen-sm-min) {
        padding: 20px 0px 50px 0px;
    }
    @media (min-width: @screen-lg-min) {
        padding: 20px 0px 50px 0px;
    }
}

.card {
    box-shadow: 0px 0px 3px 2px rgba(0,0,0,0.08);

    &-image {
        background-color: #fff;
        padding: 10px;
    }
    &-content {
        padding: 10px;
        background: #f7f7f7;
    }
    &-name {
        margin-bottom: 10px;
        text-transform: uppercase;
    }
    &-description {
        margin: 10px 0px;
        color: @text-muted;
    }
    &-price {
        margin: 10px 0px;
        font-weight: bold;
        font-size: 1.6rem;
    }
    &-button {
        margin-top: 10px;
    }
}

// Bootstrap select customization
.bootstrap-select {
    button {
        border-radius: 0;
        padding-right: 40px !important;
        font-weight: 600;
    }
    .caret {
        border: 0;
        background: @brand-primary;
        display: block;
        position: absolute;
        margin: 0;
        height: 34px;
        width: 34px;
        top: 1px !important;
        right: 0 !important;
        &:before {
            font-family: FontAwesome;
            vertical-align: middle;
            border: 0;
            content: "\f107"; // fa-angle-down
            display: inline-block;
            width: auto;
            color: #fff;
            top: 3px;
            left: 10px;
            position: absolute;
            font-size: 2rem;
        }
    }
}

.breadcrumbs {
    padding: 0;
    margin-bottom: 30px;
    list-style: none;
    font-size: 1.2rem;

    > li {
        display: inline-block;
        color: @text-muted;

        + li:before {
            content: "\00BB";
            padding: 0px 6px 0px 3px;
        }
    }
}

h1.page-title {
    margin: 40px 0px;
}

.list-group-item {
    border-radius: 0 !important;
}

a.list-group-item {
    color: @brand-primary;
    font-size: 1.2rem;
}

.modal-event-date {
    font-size: 18px;
    font-weight: bold;
    color: @brand-primary;
    margin-bottom: 15px;
    margin-top: 15px;
}

.modal-event-time {
    font-size: 18px;
    font-weight: bold;
    color: @brand-primary;
    i.notfirst {
        color: white;
    }
    i.first {
        display: inline;
    }
}

.modal-description-text {
    margin-top: 25px;
}

.modal-event-icon {
    padding-right: 15px;
    font-size: 22px;
    font-weight: bold;
    color: #aaa;
}

