.navigation-top {
    .clearfix;
    background: @brand-primary;
    border-bottom: 1px solid #fff;

    .logo {
        display: inline-block;
        padding: 20px;

        img {
            height: 50px;
            max-width: 100%;
        }

        @media (max-width: @screen-sm-max) {
            width: 75%;
        }
    }

    .slogan {
        .pull-right;
        color: #fff;
        padding: 20px;
        text-align: right;
    }

    .mobile-nav-toggle {
        float: right;
        display: block;
        position: relative;
        background: none;
        border: 0;
        padding: 0;
        margin: 0;
        width: 50px;
        height: 36px;
        @media (min-width: @screen-sm-min) {
            height: 50px;
            width: 60px;
        }

        @media (min-width: @screen-md-min) {
            display: none;
        }

        &:focus, &:active {
            outline: 0;
        }

        span {
            left: 12px;
            margin-top: -3px;
        }

        span, span:before, span:after {
            background: #fff;
            border-radius: 1px;
            width: 36px;
            height: 5px;
            position: absolute;
            display: block;
            content: "";
            cursor: pointer;
            transition: all 0.1s ease-in-out;

        }
        span:before {
            top: -10px;
            left: 0;
        }
        span:after {
            bottom: -10px;
            left: 0;
        }

        &.active span {
            background-color: transparent;
            &:before, &:after {
                top: 0;
            }
            &:before {
                transform: rotate(-45deg);
            }
            &:after {
                transform: rotate(45deg);
            }
        }

        @media (max-width: @screen-sm-max) {
            height: 90px;
            width: 25%;
        }
    }
}

.collapsible-menu {
    &.collapse {
        @media (min-width: @screen-md-min) {
            display: block !important;
            height: auto !important;
        }
    }
}

.navigation-pages {
    .clearfix;
    background: @brand-primary;

    @media (min-width: @screen-md-min) {
        display: flex;
        flex-flow: row nowrap;
    }

    ul {
        padding: 0px 10px;
        margin: 0;
        list-style: none;
        @media (min-width: @screen-lg-min) {
            padding: 0px 20px;
        }

        li {
            @media (min-width: @screen-md-min) {
                display: inline-block;
                + li:before {
                    content: "|";
                    color: #fff;
                    display: inline-block;
                    vertical-align: middle;
                }
            }
        }
        a {
            color: #fff;
            font-weight: bold;
            padding: 12px 8px;
            display: block;
            @media (min-width: @screen-md-min) {
                display: inline-block;
                vertical-align: middle;
            }
            @media (min-width: @screen-lg-min) {
                font-size: 1.6rem;
                padding: 12px 10px;
            }
        }
        li {
            ul {
                @media (min-width: @screen-md-min) {
                    display: none;
                    position: absolute;
                    z-index: 10;
                    background-color: @brand-primary;
                    border-top: 1px solid white;
                }
            }
            &:hover ul {
                display: block;
            }
            ul {
                @media (max-width: @screen-md) {
                    display: block;
                }
            }
        }
    }

    ul.left-nav li ul li {
        display: block;
        &:before {
            content: "";
        }
    }

    .left-nav {
        @media (min-width: @screen-md-min) {
            flex: 0 auto;
        }
    }
    .right-nav {
        @media (max-width: @screen-sm-max) {
            border-top: 1px solid #fff;
        }
        @media (min-width: @screen-md-min) {
            border-left: 1px solid #fff;
            flex: 1 auto;
            text-align: center;
            white-space: nowrap;
        }
        i {
            margin-right: 2px;
        }
    }
}

.navbar-fixed-top {
    display: none;
    opacity: 0;
    visibility: hidden;
    transform: translate3d(0, -100px, 0);
    transition: all 0.3s;

    @media (min-width: @screen-md-min) {
        display: block;
        &.visible {
            transform: translate3d(0, 0, 0);
            opacity: 1;
            visibility: visible;
            transition: all 0.15s;
        }
    }

    html.cms-toolbar-expanded & {
        top: 30px;
    }
}
